import React from "react";
import { ArrowLeftRight, Calculator, Link as LinkIcon, Sprout } from "lucide-react";
import LINKS from "../navigation/links";
import StickyTiledTableOfContents from "../shared-components/StickyTiledTableOfContents";
import { TileWithImageProps } from "../tile/Tile";

const tiles: Array<TileWithImageProps> = [
  {
    title: LINKS.GET_STARTED_INTRODUCTION.text,
    subtitle: "Get acquainted",
    to: LINKS.GET_STARTED_INTRODUCTION.linkTo,
    img: <Sprout />,
  },
  {
    title: LINKS.GET_STARTED_UNIFIED_API.text,
    subtitle: "Pull test data",
    to: LINKS.GET_STARTED_UNIFIED_API.linkTo,
    img: <ArrowLeftRight />,
  },
  {
    title: LINKS.GET_STARTED_LINKED_ACCOUNT.text,
    subtitle: "Create a connection",
    to: LINKS.GET_STARTED_LINKED_ACCOUNT.linkTo,
    img: <LinkIcon />,
  },
  {
    title: LINKS.GET_STARTED_LINK.text,
    subtitle: "Add to your frontend",
    to: LINKS.GET_STARTED_LINK.linkTo,
    img: <Calculator />,
  },
];

const GetStartedLeftSidebar = () => (
  <StickyTiledTableOfContents tiles={tiles} scrollHeightPercentage={90} />
);

export default GetStartedLeftSidebar;
