import styled from "styled-components";
import OnboardingStep from "../linking-flow/OnboardingStep";

const GetStartedOnboardingStep = styled(OnboardingStep)`
  margin-top: 24px;

  && {
    h6 {
      margin-bottom: 16px;
    }
  }
`;

export default GetStartedOnboardingStep;
