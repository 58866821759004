import classNames from "classnames";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { palette } from "styles/theme";

type Props = Pick<React.ComponentProps<"div">, "className"> & {
  /**
   * Contents of a column on the left side
   */
  columnA: React.ReactElement;

  /**
   * Contents of a column on the right side
   */
  columnB: React.ReactElement;

  /**
   * Padding inbetween columns (px)
   */
  padding?: number;

  /**
   * Fade content behind the section via an overlapping shadow
   * and prevent pointer interaction
   */
  fadeBackground?: boolean;

  /**
   * Combined width of both columns relative to the container,
   * in Bootstrap sizing (twelfths)
   */
  sectionWidth?: number;
};

// Fades content behind, with offset to account for any padding
const Wrapper = styled(Row)<{ $fadeBackground: boolean }>`
  ${({ $fadeBackground }) =>
    $fadeBackground
      ? css`
          &&& {
            @media (min-width: 768px) {
              position: relative;
              width: auto;
              margin-left: -40px;
              margin-right: -40px;
              padding-left: 40px;
              padding-right: 40px;

              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: calc(100% - 75px);
                top: 50px;
                background: ${palette.white};
                box-shadow: 0 0 48px 48px ${palette.white};
              }
            }
          }
        `
      : css`
          pointer-events: none !important;
        `}
`;

const Section = styled(Col)`
  margin-bottom: 24px;
  width: 100%;
`;

export const ContentContainer = styled(Row)`
  &&& {
    margin: 0;

    @media (max-width: 991px) {
      max-width: 640px;
      margin: auto;
    }
  }
`;

const Content = styled(Col)<{ $padding: number }>`
  &&& {
    margin-bottom: 24px;
    width: 100%;
    @media (min-width: 768px) {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    @media (min-width: 992px) {
      &:last-of-type {
        padding-left: ${({ $padding }) => $padding}px;
      }
      &:first-of-type {
        padding-right: ${({ $padding }) => $padding}px;
      }
    }
    padding-left: 0px;
    padding-right: 0px;
  }
`;

/**
 * Used to put two columns of content next to each other,
 * centering the whole container. The first column is smaller.
 */
const TwoColumnCenteredSection = ({
  columnA,
  columnB,
  padding = 0,
  fadeBackground = false,
  sectionWidth = 7,
  className,
}: Props) => (
  <Wrapper
    $fadeBackground={fadeBackground}
    className={classNames("d-flex justify-content-center", className)}
  >
    <Section lg={sectionWidth}>
      <ContentContainer>
        <Content lg={6} $padding={padding}>
          {columnA}
        </Content>
        <Content lg={6} $padding={padding}>
          {columnB}
        </Content>
      </ContentContainer>
    </Section>
  </Wrapper>
);

export default TwoColumnCenteredSection;
