import classNames from "classnames";
import DeprecatedH2 from "components/deprecated/DeprecatedH2";
import DeprecatedH6 from "components/deprecated/DeprecatedH6";
import React from "react";
import { Col, Row } from "react-bootstrap";

interface Props {
  stepLabel: string;
  stepTitle: string;
  stepContent: any;
  TopRightElement?: JSX.Element | null;
  className?: string | undefined;
}

const OnboardingStep = ({
  stepLabel,
  stepTitle,
  stepContent,
  TopRightElement,
  className,
}: Props) => (
  <Row className={classNames("onboarding-step", className)}>
    <Col>
      <DeprecatedH6 className="text-uppercase text-secondary">{stepLabel}</DeprecatedH6>
      <Row>
        <Col>
          <DeprecatedH2>{stepTitle}</DeprecatedH2>
        </Col>
        {TopRightElement ? (
          <Col xs={4}>
            <p className="text-right small text-muted">{TopRightElement}</p>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row>
        <Col>{stepContent}</Col>
      </Row>
    </Col>
  </Row>
);

export default OnboardingStep;
