import { GettingStartedSidebarContentBox } from "components/docs/shared-components/StickyRightSidebar";
import React from "react";
import styled from "styled-components";
import { spectrum } from "styles/theme";

interface Props {
  /**
   * The title of the Aside
   */
  title: React.ReactElement | string | undefined;

  /**
   * The actual content of the Aside
   */
  content: React.ReactElement | string;

  /**
   * The actual content of the Aside
   */
  Icon: React.ReactElement | undefined;

  /**
   * The background color of the Aside
   */
  backgroundColor?: string;

  /**
   * The icon color of the Aside
   */
  iconColor?: string;
}

/**
 * Shows a dark tooltip with given content that shows when hovering the parent element.
 * Uses Callout under the hood with the same styling - this just encapsulates some of the
 * display logic. Contents of the tooltip are just whatever children we pass in.
 */

const AsideBarContent = ({
  Icon,
  backgroundColor = spectrum.indigo0,
  iconColor = spectrum.indigo50,
  content,
  title = undefined,
}: Props) => {
  const Container = styled(GettingStartedSidebarContentBox)`
    background: ${backgroundColor};
    margin-bottom: 24px;

    svg {
      color: ${iconColor};
    }
  `;

  return (
    <>
      <Container>
        {title && (
          <div className=" mb-4 text-base aside-bar-content flex items-center">
            <div className="flex mr-3">{Icon !== undefined && Icon}</div>
            <div className="flex text-base">{title}</div>
          </div>
        )}
        {content && <div>{content}</div>}
      </Container>
    </>
  );
};

export default AsideBarContent;
