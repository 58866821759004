import React from "react";
import styled from "styled-components";
import { palette } from "styles/theme";

const GetStartedURLBox = styled.div.attrs({ className: "mt-2" })`
  background: #00102f;
  border-radius: 6px;
  padding: 10px 12px;
  margin-bottom: 32px;

  &&& {
    code {
      background: #00102f;
      padding: 0px;

      color: ${palette.white};
      font-size: 13px;
      line-height: 24px;
    }
  }
`;

export default GetStartedURLBox;
