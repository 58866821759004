import React from "react";
import SmallWidthSection from "../sections/SmallWidthSection";

type Props = Pick<React.ComponentProps<typeof SmallWidthSection>, "fadeBackground" | "className">;

/**
 * Creates a small width divider
 */
const SectionDivider = ({ fadeBackground = true, className }: Props) => (
  <SmallWidthSection fadeBackground={fadeBackground} className={className}>
    <hr />
  </SmallWidthSection>
);

export default SectionDivider;
